@use 'assets/variables.scss' as *;

.expense-pie-card {
  background: #ffffff;
  box-shadow: $box-shadow;
  width: 400px;
  border-radius: 8px;
  padding: 25px;
  .total-label {
    font-weight: 600;
    font-size: 16px;
    color: #828282;
  }
  .total-value {
    font-weight: 500;
    font-size: 32px;
    color: #0b1354;
  }
  .month {
    font-weight: 400;
    font-size: 14px;
    color: #4f4f4f;
  }
  .hor-bar {
    margin-top: 15px;
    margin-bottom: 15px;
    border-bottom: 2px solid #ececec;
  }
}
