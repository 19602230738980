#root,
#app-container,
#page-container {
  height: 100%;
}
body {
  height: calc(100vh - 16px);
  overflow: hidden;
}

#app-container {
  display: grid;
  grid-template-rows: 70px calc(100% - 70px);
  background-color: #f8fafd;
}
#page-container {
  overflow: auto;
}
