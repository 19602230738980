@use 'assets/variables.scss' as *;

.storybook {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  .listoficons {
    width: 20px;
    height: 20px;
    display: grid;
    grid-template-columns: 10px 10px;
  }
}
