@use 'assets/variables.scss' as *;
.navBar {
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  top: 0;
  position: sticky;
  background-color: $pure-white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.09);
  z-index: 900;

  .navBarContainer {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;

    .navBarFlex {
      display: flex;
      align-items: center;

      .navBarLogo {
        height: 23px;
        margin-right: 36px;
        padding-right: 36px;
        border-right: 1px solid $coolgrey300;
        cursor: pointer;
      }
      .navBarLinks {
        font-weight: 500;
        padding: 7px 14px;
        margin-right: 12px;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
          background: $blue50;
        }
      }
      .currentLink {
        background: $blue800;
        color: $pure-white;
        border-radius: 5px;
        &:hover {
          background: $blue700;
        }
      }
    }

    .navBarChildren {
      margin-left: auto;
    }
  }

  .width {
    margin-right: auto;
    margin-left: auto;
    &-HD {
      max-width: 720px;
    }
    &-FHD {
      max-width: 1080px;
    }
    &-QHD {
      max-width: 1440px;
    }
    &-UHD {
      max-width: 2160px;
    }
  }
}
