@use 'assets/variables.scss' as *;

.item-container {
  width: 800px;
  height: 60px;
  border: 1px solid $coolgrey300;
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  .category {
    rotate: 45deg;
    transform: translate(15px, 40px);
  }
  .description {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
  }
  .amount {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
