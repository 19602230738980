@use 'assets/variables.scss' as *;

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
.skeleton-container {
  width: 800px;
  height: 60px;
  border: 1px solid $coolgrey300;
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;

  .category {
    height: 15%;
    width: 50%;
    rotate: 45deg;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 0.25rem;
  }
  .description {
    width: 600px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .line {
    height: 12px;
    width: 80%;
    border-radius: 0.25rem;
  }
  .amount {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
