@use 'assets/variables.scss' as *;

.progress-card {
  background-color: white;
  box-shadow: $box-shadow;
  border-radius: 8px;
  height: 110px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: grid;
  grid-template-rows: 37px 29px 15px 25px;
  .title {
    font-weight: 600;
    font-size: 17px;
    display: flex;
    justify-content: space-between;
    .input-months {
      width: '35px';
    }
  }
  .current-value {
    font-weight: 600;
    font-size: 20px;
    color: #00a2ed;
  }
  .current-value-neg {
    font-weight: 600;
    font-size: 20px;
    color: $orange500;
  }

  .target-value {
    display: flex;
    flex-direction: row-reverse;
  }
  .bar-parent {
    background-color: #f3f4f6;
    height: 10px;
    width: 100%;
    border-radius: 5px;
    .bar-child {
      height: 100%;
      background: #00a2ed;
      border-radius: 5px;
    }
  }
}
