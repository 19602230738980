@use 'assets/variables.scss' as *;

.loader {
  height: 100%;
  display: flex;
  align-items: center;
  width: 48px;
  height: 48px;
  margin: 30px auto;
  border: 6px solid $blue100;
  border-bottom-color: $blue700;
  border-radius: 50%;
  animation: rotation 0.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
