.skeleton-label {
  width: 120px;
  height: 45px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
  .label {
    margin-bottom: 0;
    height: 12px;
    width: 110px;
    border-radius: 10px;
  }
}
