@use 'assets/variables.scss' as *;

.button {
  width: fit-content;
  height: 40px;
  border-radius: 8px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  border: none;

  color: $pure-white;

  &:focus {
    box-shadow: 0px 0px 0px 2.13333px $pure-white, 0px 0px 0px 4.26667px $blue400;
  }
}

.primary {
  background: $blue800;
  &:hover {
    background: $blue700;
  }
  &:active {
    background: $blue600;
  }
  &:focus {
    border-radius: 8px;
  }
  &:disabled {
    background: $coolgrey300;
  }
}

.secondary {
  background: $pure-white;
  color: $button-secondary-text;
  &:hover {
    background: $coolgrey50;
  }
  &:active {
    background: $coolgrey100;
  }
  &:focus {
    border: 1px solid #d1d5db;
  }
  &:disabled {
    color: $coolgrey300;
    background: $pure-white;
  }
}
.danger {
  background: $orange500;
  &:hover {
    background: $orange400;
  }
  &:active {
    background: $orange300;
  }
  &:disabled {
    background: $coolgrey300;
  }
}
.success {
  background: $green500;
  &:hover {
    background: $green400;
  }
  &:active {
    background: $green300;
  }
  &:disabled {
    background: $coolgrey300;
  }
}
.tertiary {
  background: $pure-white;
  color: $coolgrey900;
  border: 1px solid $coolgrey300;
  &:hover {
    background: $coolgrey50;
  }
  &:active {
    background: $coolgrey100;
  }
  &:disabled {
    border: 1px solid $coolgrey200;
    color: $coolgrey300;
  }
}
