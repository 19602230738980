@use 'assets/variables.scss' as *;

.auth-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background: transparentize($color: #000000, $amount: 0.5);
  z-index: 100;

  .auth-layout {
    margin: auto;
    margin-top: 55px;
    padding: 14px;
    width: 200px;
    background-color: $coolgrey100;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-gap: 5px;
    box-shadow: $box-shadow;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
