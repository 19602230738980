@use 'assets/variables.scss' as *;

.form-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background: transparentize($color: #000000, $amount: 0.5);

  .form-layout {
    margin: auto;
    margin-top: 55px;
    padding: 14px;
    width: 400px;
    background-color: $coolgrey100;
    display: grid;
    grid-template-rows: auto 1fr 1fr 1fr 1fr 1fr 1fr auto;
    grid-gap: 5px;
    box-shadow: $box-shadow;
    border-radius: 5px;

    .input-field {
      display: flex;
      flex-direction: column;
    }
    .actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
