$coolgrey50: #f8fafd;
$coolgrey100: #f3f4f6;
$coolgrey200: #e5e7eb;
$coolgrey300: #d1d5db;
$coolgrey400: #9ca3af;
$coolgrey500: #6b7280;
$coolgrey600: #4b5563;
$coolgrey700: #374151;
$coolgrey800: #1f2937;
$coolgrey900: #111827;
$blue50: #e0f4fd;
$blue100: #b0e3fa;
$blue200: #7bd0f6;
$blue300: #43bef2;
$blue400: #00b0f0;
$blue500: #00a2ed;
$blue600: #0095df;
$blue700: #0082cb;
$blue800: #0071b7;
$blue900: #005196;
$orange50: #fceeed;
$orange100: #f6cac7;
$orange200: #f1b0ac;
$orange300: #eb8c86;
$orange400: #e7766e;
$orange500: #e1544a;
$orange600: #cd4c43;
$orange700: #a03c35;
$orange800: #7c2e29;
$orange900: #5f231f;
$green50: #eff8f2;
$green100: #cee9d8;
$green200: #b6dec5;
$green300: #95cfab;
$green400: #81c59a;
$green500: #61b781;
$green600: #58a775;
$green700: #45825c;
$green800: #356547;
$green900: #5f231f;

$pure-white: #ffffff;

$button-secondary-text: #3000b7;

// $primary-color: rgb(177, 234, 253);

// $primary-color2: #36b0d6;
// $primary-color3: #14d5e3;

$primary-color: $coolgrey600;
$primary-color2: $coolgrey300;
$primary-color3: $coolgrey100;

$box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 1px 6px rgba(0, 0, 0, 0.06);
